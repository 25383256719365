.App {
  text-align: center;
}
body {
  background-color: #FD3921;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #FD3921;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.productdetails {
  background-color: #FD3921;;
  min-height: 466px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}
.product-page {
  background-color: #FD3921;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}
.mobile-product-page {
  /* display: none; */
  margin-left: 30px;
}
@media only screen and (min-width: 600px){
  .product-page {
    min-height: 80vh;
    overflow: auto;
    margin-top: -30px;
  }
  .product-page::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
}
@media only screen and (max-width: 600px){
  .product-page {
    align-items: center;
    justify-content: center;
  }
}

.App-link {
  color: #61dafb;
}
body, body * {
  font-family: 'Breda Two' !important;
  }
  
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* finalstart */
.homepage {
  /* background-image: image('images.jpg'); */
  /* background-image: url('/zero-zero/public/images/images1.jpg'); */
  background-color: #FD3921;
  color: #000000;
  min-height: 206px;
  /* color: white;
  margin-top: -16px; */
}
.text{
  /* font-family: Breda Two;*/
  font-size: 18px;
  font-weight: 700;
  line-height: 16px; 
  letter-spacing: 0px;
  margin-top: 0px;
  margin-bottom: 5px;
 }
 .product-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px; 
  letter-spacing: 0px;
  margin-top: 0px;
  margin-bottom: 5px;
  text-align: center;
 }
 .productdet-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 16px; 
  letter-spacing: 0px;
  margin-top: 0px;
  margin-bottom: 5px;
  text-align: left;
 }
 @media only screen and (max-width: 600px){
  .product-text {
    font-size: 13px;
    line-height: 16px; 
  }
  .text{
    font-size: 13px;
    margin-bottom: 0px;
  }
}
  .lg{
    font-size: 26px;
    line-height: 26px; 
  }
  .md{
    font-size: 20px;
    line-height: 22px; 
  }
  .sm{
    font-size: 12px;
    line-height: 18px; 
  }

  input,
input::-webkit-input-placeholder {
    font-size: 25px;
    line-height: 4;
}
@media only screen and (max-width: 600px){
  input,
input::-webkit-input-placeholder {
    font-size: 14px;
    line-height: 4;
}
.sm{
  font-size: 11px;
}
}

 .shop {
  width: 365px;
  height: 50px;
  margin-left: 40px;
  padding-top: 30px;

  /* font-family: Breda Two; */
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  text-transform: uppercase;
  color: #000000;
}
@media only screen and (max-width: 600px){
  .shop {
  /* STYLES HERE for DEVICES with physical max-screen width of 480px */
   width: 300px;
  }
  .footer-item{
    width: 300px;
  }
}
@media only screen and (min-width: 600px){
  .shop {
  /* STYLES HERE for DEVICES with physical max-screen width of 480px */
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   width: 85%;
  }
}
@media only screen and (min-width: 600px){
  .footer-hide {
    display: none;
  }
  .mobile-product-page {
    display: none;
  }
}
@media only screen and (max-width: 600px){
  /* .footer{
    padding-top: 30px !important;
  } */
  .product-page {
    display: none;
  }
}

 /* final end */
.homepage-nav {
  display: flex;
  margin: 10px 40px;
  justify-content: space-between;
}
.product-navlink {
  text-decoration: none;
    color: #000000;
    /* @media only screen and (min-device-width: 480px) {
      height: 230px;
    } */
    
}

.homepage-navlist {
  display: flex;
  justify-content: space-between;
}
.navlist {
  margin: 10px 10px;
  text-decoration: none;
  color: white;
}
/* .text{
 font-size: 12px;
 font-weight: 400;
} */
.homepage-address {
  /* display: flex;
  flex-direction: column; */
  /* align-items: center;
  justify-content: start; */
  font-size: 20px;
  gap: 1px;
  margin-left: 30px;
}
.product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  /* height: 290px; */
  width: 200px;
  /* margin-top: -300px; */
    /* height: 290px; */
}
.mobile-product {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media only screen and (min-width: 600px){
  .mobile-product {
    gap: 20px;
  }
  .product {
  /* STYLES HERE for DEVICES with physical max-screen width of 480px */
  flex-direction: row;
justify-content: space-between;
gap: 70px;
height: 290px;
width: 60%;
margin-bottom: 75px;
  }
}
.product-container {
  /* margin: 20px 50px; */
  width: 200px;
  height: 192px;
}
@media only screen and (max-width: 600px){
  .product-container {
    width: 130px;
    height: 190px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.product-img {
  height: 200px;
  width: 200px;
  object-fit: contain;
}
.productdetails-img {
  height: 560px;
  width: 450px;
  object-fit: contain;
}
.productdetails-text {
  width: 425px;
  height: 450px;
}
.bag-count {
  display: flex;
  justify-content: space-between;
  height: 28px;
  width: 70px;
  text-align: center;
  border: 1px solid black;
  padding: 0 10px
}
.row {
  display: flex;
  justify-content: space-between;
  font-family: 'Scout' !important;
  width: 90%;
  margin-top: 30px;
}
.addtobag {
  background-color: #FD3921;
  width: 20px;
  height: 20px;
  border: 1px solid black;
}
.bag-button{
  border: 1px solid black;
  width: 260px;
  height: 30px;
  background-color: #FD3921;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  color: #000000;
}
/* .product-text {
  margin-left: 20px;
  font-size: 15px;
  font-weight: 200;
  color: white;
} */
.row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@font-face {
  font-family: "Breda Two";   /*Can be any text*/
  src: local("BredaTwo-Light"),
    url("./fonts/BredaTwo-Light.ttf") format("truetype");
}

.product-nocontent {
  max-width: 300px;
}

@media only screen and (max-width: 600px){
  .product-img {
    height: 155px;
    width: 130px;
  }
.productdetails {
  background-color: #FD3921;;
  /* min-height: 560px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  margin-left: 30px;
  /* color: white; */
}
.productdetails-img {
  height: 250px;
  width: 250px;
  object-fit: contain;
}
.productdetails-text {
  width: 100%;
  height: 250px;
  margin-top: 20px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 25px;
}
.shop {
  height: 20px;
  /* margin-top: 30px; */
  margin-left: 20px;
}
.row {
  margin-top: 15px;
}
.bag-count {
  margin-right: 20px;
}
.footer-item {
  width: 300px !important;
}
}
