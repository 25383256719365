.footer {
    background-color: #FD3921;
    color: #000000;
    min-height: 30vh;
    padding-top: 4px;
  }
  .footer-item {
    width: 365px;
    height: 130px;
    margin-left: 40px;
    padding-top: 30px;

    /* font-family: Breda Two; */
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    text-transform: uppercase;
    color: #000000;
  }
.footer-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.subscribe {
    display: flex;
    justify-content: space-between;
}
.input{
    height: 22px;
    width: 198px;
    margin-bottom: 10px;
    background-color: #FD3921;
    text-decoration: none;
    border: 0px;
    border-bottom: 2px solid;
}

/* input, select, textarea{
    color: #000000;
} */
input::placeholder{
    color: #000000;
    font-size: 18px;
    font-weight: 700;
    line-height: 16px; 
    letter-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 5px;
  }

  @media only screen and (max-width: 600px){
    .footer {
      min-height: 25vh;
    }
    .input {
      height: 18px;
      width: 80px;
      border-bottom: 1px solid;
    }
    .search {
      margin-left: 10px;
      margin-top: -2px !important;
      display: none;
    }
    input::placeholder{
      color: #000000;
      font-size: 13px;
      font-weight: 700;
      line-height: 16px; 
      letter-spacing: 0px;
      margin-top: 0px;
      margin-bottom: 5px;
    }
  }